import { useMemo } from "react";
import { BarChart, Bar, XAxis, YAxis, Tooltip } from "recharts";
import { useAppSelector } from "store/hooks";

type ConceptOverviewData = {
  concept: string;
  matches: number;
};

type ConceptOverviewProps = {
  setSelectedConcept: (concept: string) => void;
};

export const ConceptOverview: React.FC<ConceptOverviewProps> = ({ setSelectedConcept }) => {
  const studyVisualizationData = useAppSelector(
    (state) => state.studyVisualization.data?.studyVisualization
  );

  const data = useMemo(() => {
    const concepts: ConceptOverviewData[] = [];
    studyVisualizationData?.concepts_matched
      .filter((concept) => concept.id == "All")
      .map((item) => {
        const exists = concepts.find((concept) => concept.concept === item.concept);
        if (!exists) {
          concepts.push({
            concept: item.concept,
            matches: item.n_matches,
          });
        } else {
          exists.matches += item.n_matches;
        }
      });

    return concepts;
  }, [studyVisualizationData?.concepts_matched]);

  const tickFormatter = (value: string) => {
    const limit = 20;

    if (value.length <= limit) return value;

    return `${value.substring(0, limit)}...`;
  };

  if (!data) return null;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginBottom: 20,
      }}
    >
      <BarChart
        width={730}
        height={350}
        data={data}
        margin={{ top: 20, bottom: 20 }}
        {...{
          overflow: "visible",
        }}
      >
        <XAxis
          dataKey="concept"
          angle={45}
          offset={5}
          height={100}
          textAnchor={"start"}
          tickFormatter={tickFormatter}
        />
        <YAxis />
        <Tooltip />
        <Bar
          dataKey="matches"
          fill="#8884d8"
          onClick={(barData: ConceptOverviewData) => setSelectedConcept(barData.concept)}
          style={{ cursor: "pointer" }}
        />
      </BarChart>
    </div>
  );
};
