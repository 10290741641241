import { faArrowLeft, faArrowRight, faComment, faUser } from "@fortawesome/free-solid-svg-icons";
import { Button, Segmented, Select, Tooltip, Typography } from "antd";
import { AntIcon } from "components/ui-kit/components/ant-icon";
import { useToken } from "components/ui-kit/core/token";
import { getAnalytics } from "logic/analytics/analytics";
import { useGetText } from "logic/internals/i18n/get-text";
import { RefObject, useCallback, useMemo } from "react";
import { useAppSelector } from "store/hooks";
import { UserInterviewState } from "store/modules/user-interviews/types";
import { ProjectSettings } from "store/modules/projects/types";
import { useStudyContainerContext } from "templates/index/workspace-page/contexts/insights-panel";
import { useDrawerContext } from "templates/index/workspace-page/contexts/drawer";

type UserInterviewDisplayOptionsMenuProps = {
  listRef: RefObject<HTMLDivElement>;
  userInterviews: UserInterviewState[];
  loading?: boolean;
  disabled?: boolean;
};

enum InterviewStatus {
  Loading = "loading",
  Error = "error",
  Done = "done",
}

type OptionsProps = {
  label: string;
  value: number;
  status?: InterviewStatus;
  questionsCount?: string;
};

type InterviewStatusColor = {
  [key in InterviewStatus]: string;
};

const interviewStatusColors: InterviewStatusColor = {
  [InterviewStatus.Done]: "green",
  [InterviewStatus.Loading]: "orange",
  [InterviewStatus.Error]: "red",
};

export const UserInterviewDisplayOptionsMenu: React.FC<UserInterviewDisplayOptionsMenuProps> = ({
  listRef,
  userInterviews,
  loading,
  disabled,
}) => {
  const token = useToken();
  const getText = useGetText();
  const analytics = getAnalytics();

  const {
    currentPage,
    currentTopic,
    viewByTopic,
    totalPages,
    totalTopics,
    setCurrentPage,
    setViewByTopic,
    setCurrentTopic,
  } = useStudyContainerContext();

  const { drawerOpen } = useDrawerContext();

  const customScriptNumberOfQuestions = useAppSelector(
    (state) => state.customScript.data?.questionsNumber
  );

  const projectSettings = useAppSelector((state) => state.projects.data.projectSettings);

  const showDisplayBy = useMemo(
    () => projectSettings === ProjectSettings.CustomScriptMode,
    [projectSettings]
  );

  const totalNumberOfQuestions = useMemo(() => {
    if (projectSettings === ProjectSettings.CustomScriptMode)
      return customScriptNumberOfQuestions ?? 1;

    return 8;
  }, [projectSettings, customScriptNumberOfQuestions]);

  const options: OptionsProps[] = useMemo(
    () =>
      Object.values(userInterviews).map((userInterview, index) => {
        const questions = userInterview.data.userInterview?.length || 1;
        const totalQuestions = totalNumberOfQuestions;
        const pageValue = userInterview.loading
          ? `${questions} / ${totalQuestions}`
          : `${totalQuestions}/${totalQuestions}`;

        let status: InterviewStatus;

        if (userInterview.loading) {
          status = InterviewStatus.Loading;
        } else if (userInterview.error) {
          status = InterviewStatus.Error;
        } else {
          status = InterviewStatus.Done;
        }

        return {
          label: `${index + 1} - ${userInterview.data.generatedSyntheticUserPersonalityDetails?.personalInformation?.fullName || ""}`,
          value: index,
          status,
          questionsCount: `${pageValue}`,
        };
      }),
    [userInterviews, totalNumberOfQuestions]
  );

  const optionsByTopic: OptionsProps[] = useMemo(
    () =>
      Object.values(userInterviews[0]?.data.topics ?? []).map((topic, index) => ({
        label: `${index + 1} - ${topic}`,
        value: index,
      })),
    [userInterviews]
  );

  const currentSetState = useMemo(
    () => (viewByTopic ? setCurrentTopic : setCurrentPage),
    [viewByTopic, setCurrentPage, setCurrentTopic]
  );

  const handleArrowClick = useCallback(
    (direction: "left" | "right") => {
      const isFirst = viewByTopic ? currentTopic === 0 : currentPage === 0;
      const isLast = viewByTopic ? currentTopic === totalTopics : currentPage === totalPages;
      const last = viewByTopic ? totalTopics : totalPages;

      listRef.current?.scrollIntoView({ behavior: "smooth" });

      if (direction === "right") {
        analytics.track(
          `workspace:study:interviews:switch-${viewByTopic ? "topic" : "sinthetic-user"}`,
          viewByTopic
            ? { direction: "forward" }
            : {
                direction: "forward",
                sinthetic_user_id: userInterviews[currentPage]?.data?.userInterviewId,
              }
        );

        if (isLast) return currentSetState(0);
        return currentSetState((prev) => prev + 1);
      }

      if (direction === "left") {
        analytics.track(
          `workspace:study:interviews:switch-${viewByTopic ? "topic" : "sinthetic-user"}`,
          viewByTopic
            ? { direction: "back" }
            : {
                direction: "back",
                sinthetic_user_id: userInterviews[currentPage]?.data?.userInterviewId,
              }
        );

        if (isFirst) return currentSetState(last);
        return currentSetState((prev) => prev - 1);
      }
    },
    [
      viewByTopic,
      currentTopic,
      currentPage,
      totalTopics,
      totalPages,
      currentSetState,
      listRef,
      userInterviews,
      analytics,
    ]
  );

  return (
    <div
      style={{
        position: "sticky",
        top: drawerOpen ? 60 : 140,
        backgroundColor: "#F6F6FF",
        zIndex: "10",
        display: "flex",
        flexDirection: "row",
        justifyContent: showDisplayBy ? "space-between" : "end",
      }}
    >
      {showDisplayBy && (
        <div>
          <Typography.Text>Display by:</Typography.Text>
          <Tooltip
            placement="topLeft"
            title={loading ? getText("page.generic.disabledWhileLoading") : ""}
          >
            <Segmented
              disabled={loading}
              value={viewByTopic ? "Topic" : "User"}
              style={{ margin: token.margin }}
              options={[
                {
                  label: "User",
                  value: "User",
                  icon: <AntIcon icon={faUser} />,
                },
                {
                  label: "Topic",
                  value: "Topic",
                  icon: <AntIcon icon={faComment} />,
                  disabled: (userInterviews[0]?.data.topics?.length ?? 0) === 0,
                },
              ]}
              onChange={(value) => {
                analytics.track("workspace:study:interviews:display-by", {
                  value: value === "Topic" ? "topic" : "user",
                });
                setViewByTopic(value === "Topic");
              }}
            />
          </Tooltip>
        </div>
      )}
      <div style={{ width: "60%", marginLeft: token.margin }}>
        <Button
          disabled={disabled}
          shape="circle"
          icon={<AntIcon icon={faArrowLeft} />}
          onClick={() => handleArrowClick("left")}
        />
        <Tooltip
          placement="topLeft"
          title={disabled ? getText("page.generic.disabledWhileLoading") : ""}
        >
          <Select
            disabled={disabled}
            allowClear={false}
            value={viewByTopic ? currentTopic : currentPage}
            placeholder={viewByTopic ? "Select a topic" : "Select an interview"}
            style={{ margin: token.margin, width: 200 }}
            onChange={(value) => currentSetState(value)}
          >
            {(viewByTopic ? optionsByTopic : options).map((option) => (
              <Select.Option key={option.value} value={option.value}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography.Text ellipsis style={{ width: 130, lineHeight: "inherit" }}>
                    {option.label}
                  </Typography.Text>
                  <div>
                    <Typography.Text
                      strong
                      style={{
                        color: option.status ? interviewStatusColors[option.status] : "black",
                      }}
                    >
                      {option.questionsCount}
                    </Typography.Text>
                  </div>
                </div>
              </Select.Option>
            ))}
          </Select>
        </Tooltip>
        <Button
          disabled={disabled}
          shape="circle"
          icon={<AntIcon icon={faArrowRight} />}
          onClick={() => handleArrowClick("right")}
        />
      </div>
    </div>
  );
};
