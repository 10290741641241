import { Input, Spin, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

type ResearchPlanInputProps = {
  id?: string;
  defaultValue: string;
  onSubmit?: (value: string, id?: string) => void;
  onInputChange?: (value: string) => void;
  onInputFocus?: () => void;
  isEditable?: boolean;
  loading?: boolean;
  compact?: boolean;
  autoFocus?: boolean;
};

const FormValuesSchema = z.object({
  text: z
    .string()
    .min(1, { message: "Text cannot be empty" })
    .max(10000, { message: "Text cannot exceed 10.000 characters" }),
});

type FormValues = z.infer<typeof FormValuesSchema>;

export const ResearchPlanInput: React.FC<ResearchPlanInputProps> = ({
  id,
  defaultValue,
  isEditable,
  onSubmit,
  onInputChange,
  onInputFocus,
  loading,
  compact,
  autoFocus,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const form = useForm<FormValues>({
    resolver: zodResolver(FormValuesSchema),
    defaultValues: { text: "" },
    reValidateMode: "onChange",
  });

  // Set default value and trigger validation
  useEffect(() => {
    form.setValue("text", defaultValue, { shouldValidate: true });
  }, [defaultValue, form]);

  useEffect(() => {
    if (submitting && !loading) {
      setSubmitting(false);
    }
  }, [loading, submitting]);

  return (
    <Spin spinning={submitting}>
      <div style={{ width: "100%" }}>
        <form
          onSubmit={form.handleSubmit((formValue) => {
            onSubmit && onSubmit(formValue.text, id);
            setSubmitting(true);
          })}
        >
          <Controller
            control={form.control}
            name="text"
            render={({ field: { value, onChange } }) => (
              <>
                <Input.TextArea
                  disabled={!isEditable}
                  autoFocus={autoFocus}
                  autoSize={{ minRows: compact ? 2 : 5, maxRows: 6 }}
                  style={{ resize: "none", backgroundColor: "#FFFFFF" }}
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value);
                    form.trigger("text"); // manually trigger validation on change
                    onInputChange && onInputChange(e.target.value);
                  }}
                  onFocus={onInputFocus}
                  onBlur={(e) => {
                    e.preventDefault();
                    form.trigger("text"); // ensure validation before submitting
                    if (form.formState.isValid && value !== defaultValue && onSubmit) {
                      onSubmit(value, id);
                      setSubmitting(true);
                    }
                  }}
                />
                <Typography.Text type="danger" style={{ fontSize: 12 }}>
                  {form.formState.errors.text?.message}
                </Typography.Text>
              </>
            )}
          />
        </form>
      </div>
    </Spin>
  );
};
