// React
import { useContext, createContext, Dispatch, SetStateAction } from "react";
// Types

export enum StudyContainerTabs {
  ResearchPlan = "ResearchPlan",
  SyntheticUsers = "SyntheticUsers",
  InsightsPanel = "InsightsPanel",
}

export enum InsightsPanelTabKeys {
  SUMMARY = "summary",
  VISUAL_SUMMARY = "visual-summary",
  ANNOTATIONS = "annotations",
  KNOWLEDGE_GRAPH = "knowledge-graph",
}

export const INTERVIEW_TAB_KEY = "interviews-";

export type InsightsPanelTab = `${typeof INTERVIEW_TAB_KEY}${number}` | InsightsPanelTabKeys;

export type StudyContainerContextProps = {
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  totalPages: number;
  setTotalPages: (value: number) => void;
  viewByTopic: boolean;
  setViewByTopic: Dispatch<SetStateAction<boolean>>;
  currentTopic: number;
  setCurrentTopic: Dispatch<SetStateAction<number>>;
  totalTopics: number;
  setTotalTopics: Dispatch<SetStateAction<number>>;
  currentAudience: number;
  setCurrentAudience: Dispatch<SetStateAction<number>>;
  currentSummaryIndex: number;
  setCurrentSummaryIndex: Dispatch<SetStateAction<number>>;
  insightsPanelTab: InsightsPanelTab;
  setInsightsPanelTab: Dispatch<SetStateAction<InsightsPanelTab>>;
  studyContainerTab: StudyContainerTabs;
  setStudyContainerTab: Dispatch<SetStateAction<StudyContainerTabs>>;
  resetStudyContainerContext: () => void;
};

const StudyContainerContextDefaults: StudyContainerContextProps = {
  currentPage: 0,
  setCurrentPage: () => {
    throw new Error(
      "to use this context, please wrap your component with StudyContainerContextProvider"
    );
  },
  totalPages: 0,
  setTotalPages: () => {
    throw new Error(
      "to use this context, please wrap your component with StudyContainerContextProvider"
    );
  },
  viewByTopic: false,
  setViewByTopic: () => {
    throw new Error(
      "to use this context, please wrap your component with StudyContainerContextProvider"
    );
  },
  currentTopic: 0,
  setCurrentTopic: () => {
    throw new Error(
      "to use this context, please wrap your component with StudyContainerContextProvider"
    );
  },
  totalTopics: 0,
  setTotalTopics: () => {
    throw new Error(
      "to use this context, please wrap your component with StudyContainerContextProvider"
    );
  },
  currentAudience: 0,
  setCurrentAudience: () => {
    throw new Error(
      "to use this context, please wrap your component with StudyContainerContextProvider"
    );
  },
  currentSummaryIndex: 0,
  setCurrentSummaryIndex: () => {
    throw new Error(
      "to use this context, please wrap your component with StudyContainerContextProvider"
    );
  },
  insightsPanelTab: `${INTERVIEW_TAB_KEY}0`,
  setInsightsPanelTab: () => {
    throw new Error(
      "to use this context, please wrap your component with StudyContainerContextProvider"
    );
  },
  studyContainerTab: StudyContainerTabs.SyntheticUsers,
  setStudyContainerTab: () => {
    throw new Error(
      "to use this context, please wrap your component with StudyContainerContextProvider"
    );
  },
  resetStudyContainerContext: () => {
    throw new Error(
      "to use this context, please wrap your component with StudyContainerContextProvider"
    );
  },
};

// Context
export const StudyContainerContext = createContext<StudyContainerContextProps>(
  StudyContainerContextDefaults
);

// Hook
export const useStudyContainerContext = (): StudyContainerContextProps =>
  useContext(StudyContainerContext);
