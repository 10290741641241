import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Flex, Input, List, Space, Typography } from "antd";
import { AntIcon } from "components/ui-kit/components/ant-icon";
import { useCallback, useMemo, useState } from "react";
import { useAppSelector } from "store/hooks";

type RecentAudiencesCardProps = {
  onClick: (suggestion: string, isLiteral?: boolean) => void;
};

export const RecentAudiences: React.FC<RecentAudiencesCardProps> = ({ onClick }) => {
  const [suggestionSelected, setSuggestionSelected] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);
  const audiences = useAppSelector((state) => state.audiences);
  const [search, setSearch] = useState<string>("");

  const previousAudiences = useMemo(() => {
    const filteredAudiences = Array.from(
      new Set(
        audiences.data.audiences
          ?.filter((audience, index, self) => {
            return (
              !!audience.description.length &&
              self.findIndex(
                (a) =>
                  a.description.toLowerCase().trim() === audience.description.toLowerCase().trim()
              ) === index
            );
          })
          .sort((a, b) => {
            const aDate = new Date(a.createdAt);
            const bDate = new Date(b.createdAt);
            return bDate.getTime() - aDate.getTime();
          })
      )
    );
    return filteredAudiences;
  }, [audiences.data.audiences]);

  const audiencesToDisplay = useMemo(() => {
    const filteredLength = previousAudiences.length;

    return previousAudiences
      .slice(0, expanded ? filteredLength : Math.min(filteredLength, 3))
      .filter((audience) => audience.description.toLowerCase().includes(search.toLowerCase()))
      .map((audience) => audience.description);
  }, [previousAudiences, expanded, search]);

  const handleClick = useCallback(
    (suggestion: string) => {
      setSuggestionSelected(true);
      setSearch("");
      expanded && setExpanded(false);
      onClick(suggestion, true);
    },
    [onClick, expanded]
  );

  const canExpand = useMemo(
    () => !suggestionSelected && previousAudiences.length > 3,
    [previousAudiences, suggestionSelected]
  );

  if (!previousAudiences.length) return null;

  return (
    <div
      style={{
        position: expanded ? "absolute" : "relative",
        bottom: 0,
        backgroundColor: expanded ? "#F5F5F5" : "transparent",
        width: "100%",
        marginTop: "auto",
        marginBottom: expanded ? 0 : 40,
        borderRadius: "8px 8px 0 0",
        height: expanded ? "50%" : "fit-content",
        overflow: expanded ? "scroll" : "visible",
      }}
    >
      <Flex
        style={{
          width: "100%",
          alignItems: "center",
          position: "sticky",
          top: 0,
          zIndex: 1,
          marginBottom: 16,
          padding: "4px 0",
          backgroundColor: expanded ? "#F5F5F5" : "transparent",
        }}
      >
        <Space
          onClick={() => canExpand && setExpanded((prev) => !prev)}
          style={{ cursor: canExpand ? "pointer" : "default" }}
        >
          <Typography.Paragraph
            type="secondary"
            style={{ marginTop: "auto", margin: 0, marginRight: 16 }}
          >
            Recent Audiences
          </Typography.Paragraph>
          {canExpand && (
            <AntIcon
              component={() => <FontAwesomeIcon icon={expanded ? faChevronDown : faChevronUp} />}
            />
          )}
        </Space>

        {expanded && (
          <Input.Search
            placeholder="Search"
            style={{ width: 200, marginLeft: "auto" }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        )}
      </Flex>
      <List
        grid={{ gutter: 16, column: 3 }}
        dataSource={audiencesToDisplay}
        renderItem={(suggestion) => (
          <List.Item>
            <Card
              size="small"
              style={{
                height: "100%",
                cursor: suggestionSelected ? "default" : "pointer",
                backgroundColor: "transparent",
              }}
              styles={{ body: { height: "100%" } }}
              onClick={() => !suggestionSelected && handleClick(suggestion)}
            >
              <Typography.Paragraph
                style={{
                  marginBlock: 0,
                  color: suggestionSelected ? "rgba(0, 0, 0, 0.45)" : "initial",
                }}
                ellipsis={{
                  rows: 2,
                  tooltip: {
                    title: suggestion,
                    overlayStyle: { minWidth: 300 },
                    overlayInnerStyle: { minWidth: 300 },
                  },
                }}
              >
                {suggestion}
              </Typography.Paragraph>
            </Card>
          </List.Item>
        )}
      />
    </div>
  );
};
