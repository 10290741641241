import { BaseChip, BaseChipProps } from "./base-chip";
import { ResearchPlanProps } from "../research-plan";
import { useResearchAssistantReady } from "logic/hooks/betaUI/handle-research-assistant-ready";

type ChipActionsProps = {
  setIsReady: (isReady: boolean) => void;
  assistantData?: ResearchPlanProps["assistantData"];
};

export const ChipActions: React.FC<ChipActionsProps> = ({ setIsReady, assistantData }) => {
  const handleReady = useResearchAssistantReady({ assistantData, setIsReady });

  const actions: BaseChipProps[] = [
    {
      onClick: handleReady,
      text: "Continue / Edit",
      skin: "primary",
    },
  ];

  return (
    <>
      {actions.map((action, i) => (
        <BaseChip key={`chip-action-${i}`} {...action} />
      ))}
    </>
  );
};
