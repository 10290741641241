import { Button, Select, Space, Tabs, TabsProps } from "antd";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { StudyVisualization } from "store/modules/study-visualization/types";
import { ConceptDetailsCard } from "./concept-details-card";
import { ConceptMatchesCard } from "./concept-matches-card";
import { useAppSelector } from "store/hooks";
import { AntIcon } from "components/ui-kit/components/ant-icon";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { TabLabel } from "./tab-label";

type IntervieweeSelectOption = {
  label: string;
  value: string;
  conceptMatch?: boolean;
};

type ConceptDetailsTabsProps = {
  selectedConcept?: string;
  setSelectedConcept: (concept: string) => void;
};

export const ConceptDetailsTabs: React.FC<ConceptDetailsTabsProps> = ({
  selectedConcept,
  setSelectedConcept,
}) => {
  const studyVisualizationData = useAppSelector(
    (state) => state.studyVisualization.data?.studyVisualization
  );

  const [selectedInterviewee, setSelectedInterviewee] = useState<string | undefined>();

  const conceptDetailsRef = useRef<HTMLDivElement>(null);

  const intervieweesSelectOptions = useMemo(() => {
    const int: IntervieweeSelectOption[] = [];

    studyVisualizationData?.concepts_matched?.forEach((entry) => {
      const exists = int.find((e) => e.value === entry.full_name);

      if (!exists) {
        int.push({
          label: entry.full_name,
          value: entry.full_name,
          conceptMatch:
            studyVisualizationData?.concepts_matched?.filter(
              (concept) =>
                concept.concept === selectedConcept && concept.full_name === entry.full_name
            ).length > 0,
        });
      }
    });

    return int;
  }, [studyVisualizationData?.concepts_matched, selectedConcept]);

  const handleArrowClick = useCallback(
    (direction: "left" | "right") => {
      const currentIndex = intervieweesSelectOptions.findIndex(
        (option) => option.value === selectedInterviewee
      );

      if (direction === "right") {
        setSelectedInterviewee(
          intervieweesSelectOptions[currentIndex + 1]?.value || intervieweesSelectOptions[0]?.value
        );
      } else {
        setSelectedInterviewee(
          intervieweesSelectOptions[currentIndex - 1]?.value ||
            intervieweesSelectOptions[intervieweesSelectOptions.length - 1]?.value
        );
      }
    },
    [intervieweesSelectOptions, selectedInterviewee]
  );

  const tabItems = useMemo(() => {
    const filteredMatrix: StudyVisualization["concepts_matched"] = [];

    studyVisualizationData?.concepts_matched
      ?.filter((concept) => concept.id !== "All")
      .forEach((entry) => {
        const exists = filteredMatrix.find((e) => e.concept === entry.concept);

        if (!exists) {
          filteredMatrix.push(entry);
        }
      });

    return filteredMatrix?.map((entry) => {
      return {
        label: <TabLabel text={entry.concept} maxWidth={200} />,
        key: entry.concept,
        children: (
          <>
            <ConceptDetailsCard
              selectedConcept={selectedConcept || ""}
              conceptDetailsRef={conceptDetailsRef}
            />
            <Space style={{ width: "100%", marginTop: 24 }} direction="vertical" size="large">
              <Space>
                <Button
                  shape="circle"
                  icon={<AntIcon icon={faArrowLeft} />}
                  onClick={() => handleArrowClick("left")}
                />
                <Select
                  style={{ width: 200 }}
                  value={selectedInterviewee}
                  onChange={setSelectedInterviewee}
                  options={intervieweesSelectOptions}
                  optionRender={(option) => (
                    <span style={{ color: option.data.conceptMatch ? "green" : "initial" }}>
                      {option.label}
                    </span>
                  )}
                />
                <Button
                  shape="circle"
                  icon={<AntIcon icon={faArrowRight} />}
                  onClick={() => handleArrowClick("right")}
                />
              </Space>

              <ConceptMatchesCard
                selectedConcept={selectedConcept || ""}
                selectedInterviewee={selectedInterviewee || ""}
              />
            </Space>
          </>
        ),
      };
    });
  }, [
    selectedConcept,
    studyVisualizationData?.concepts_matched,
    selectedInterviewee,
    intervieweesSelectOptions,
    handleArrowClick,
  ]);

  const handleTabChange: TabsProps["onChange"] = (activeKey) => {
    setSelectedConcept(activeKey);
  };

  useEffect(() => {
    if (!selectedInterviewee && intervieweesSelectOptions && intervieweesSelectOptions[0]) {
      setSelectedInterviewee(intervieweesSelectOptions[0].value);
    }
  }, [intervieweesSelectOptions, selectedInterviewee]);

  return (
    <Tabs
      tabBarStyle={{
        maxWidth: 250,
      }}
      items={tabItems}
      tabPosition="left"
      activeKey={selectedConcept}
      onChange={handleTabChange}
    />
  );
};
