import { useCallback, useEffect, useMemo } from "react";
import {
  generateKnowledgeGraph,
  killKnowledgeGraphPolling,
  pollKnowledgeGraph,
} from "store/modules/knowledge-graph/actions";
import { useAppDispatch, useAppSelector } from "store/hooks";
import useDebouncedCallback from "logic/hooks/use-debounce";
import { KnowledgeGraphStatus } from "store/modules/knowledge-graph/schemas";
import { Button, Flex, Spin, theme, Typography, Image, Space, Progress, Card } from "antd";
import { STUDY_ROUTE } from "templates/studies/study.routes";
import { FileImageOutlined, FilePdfOutlined } from "@ant-design/icons";

enum KnowledgeGraphDownloadFormats {
  PNG = "png",
  PDF = "pdf",
}

export const KnowledgeGraphTab: React.FC = () => {
  const { token } = theme.useToken();
  const dispatch = useAppDispatch();

  const studyId = useAppSelector((state) => state.study.data?.id);
  const userInterviews = useAppSelector((state) => state.userInterviews);
  const knowledgeGraph = useAppSelector((state) => state.knowledgeGraph);

  const status = useMemo(
    () => knowledgeGraph.data?.knowledgeGraph?.status,
    [knowledgeGraph.data?.knowledgeGraph?.status]
  );

  const allInterviewsAvailable = useMemo(() => {
    const allInterviews = Object.values(userInterviews.data || {});

    return (
      !!allInterviews &&
      !userInterviews.loading &&
      !allInterviews.some((interview) => interview.loading) &&
      !allInterviews.some((interview) => interview.error)
    );
  }, [userInterviews]);

  const computedStudyId = useMemo(() => {
    return studyId || Object.values(userInterviews.data || {})[0]?.data.studyId;
  }, [studyId, userInterviews.data]);

  const handleGenerateKnowledgeGraph = useDebouncedCallback(() => {
    if (!computedStudyId) return;
    dispatch(generateKnowledgeGraph({ studyId: computedStudyId }));
  });

  useEffect(() => {
    if (!computedStudyId) return;
    if (!knowledgeGraph.data && allInterviewsAvailable) {
      dispatch(pollKnowledgeGraph({ studyId: computedStudyId }));
    }

    if (
      status &&
      status !== KnowledgeGraphStatus.DONE &&
      status !== KnowledgeGraphStatus.FAILED &&
      !knowledgeGraph.generating
    ) {
      dispatch(pollKnowledgeGraph({ studyId: computedStudyId }));
    }
  }, [
    knowledgeGraph.data,
    knowledgeGraph.generating,
    allInterviewsAvailable,
    computedStudyId,
    dispatch,
    status,
  ]);

  useEffect(() => {
    return () => {
      killKnowledgeGraphPolling();
    };
  }, []);

  const base64Chart = useMemo(() => {
    if (!knowledgeGraph.data?.knowledgeGraph?.message) return;

    return Buffer.from(knowledgeGraph.data.knowledgeGraph.message).toString("base64");
  }, [knowledgeGraph.data?.knowledgeGraph?.message]);

  const previewUrl = useMemo(() => {
    if (!base64Chart) return;

    return `https://mermaid.ink/img/${base64Chart}`;
  }, [base64Chart]);

  const handleDownload = useCallback(
    async (format: KnowledgeGraphDownloadFormats) => {
      if (!base64Chart) return;

      let url = "https://mermaid.ink/";

      switch (format) {
        case KnowledgeGraphDownloadFormats.PDF:
          url += `pdf/${base64Chart}?fit=1`;
          break;
        case KnowledgeGraphDownloadFormats.PNG:
          url += `img/${base64Chart}?type=${format}&backgroundColor=white`;
          break;
      }

      if (url) {
        try {
          const response = await fetch(url);
          const blob = await response.blob();
          const downloadUrl = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.download = `knowledge-graph.${format}`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(downloadUrl);
        } catch (error) {
          // Handle the error silently or show an error message to the user
          // For example, you could use a notification system or update the UI
          // to inform the user that the download failed
        }
      }
    },
    [base64Chart]
  );

  const handleImageClick = useCallback(() => {
    if (computedStudyId) {
      window.open(`${STUDY_ROUTE.getHref(computedStudyId)}/knowledge-graph`, "_blank");
    }
  }, [computedStudyId]);

  const renderProgress = useMemo(() => {
    if (!knowledgeGraph.data?.knowledgeGraph?.message) return;

    const message = knowledgeGraph.data.knowledgeGraph.message;
    let percent = 0;
    let statusText = "";

    if (message.startsWith("Processing interview")) {
      const match = message.match(/Processing interview (\d+) of (\d+)/);
      if (match && match[1] && match[2]) {
        const current = parseInt(match[1], 10);
        const total = parseInt(match[2], 10);
        percent = Math.round((current / total) * 90); // 90% for interviews
        statusText = `Processing interviews (${current}/${total})`;
      }
    } else if (message.includes("Optimizing")) {
      percent = 95;
      statusText = "Optimizing knowledge graph";
    }

    return {
      percent,
      statusText,
    };
  }, [knowledgeGraph.data?.knowledgeGraph?.message]);

  return (
    <Spin
      spinning={!!knowledgeGraph.loading && !knowledgeGraph.generating}
      style={{
        maxWidth: "978px",
        alignContent: "center",
        margin: "auto",
        backgroundColor: "#FAFAFA",
        padding: token.paddingXL,
        marginBottom: 24,
      }}
    >
      {!knowledgeGraph.data?.knowledgeGraph && !knowledgeGraph.generating && (
        <Flex justify="center" align="center" style={{ marginTop: 32 }}>
          <Button
            type="primary"
            onClick={handleGenerateKnowledgeGraph}
            disabled={!allInterviewsAvailable}
          >
            Generate Knowledge Graph
          </Button>
        </Flex>
      )}

      {(status === KnowledgeGraphStatus.CREATED || status === KnowledgeGraphStatus.RUNNING) &&
        renderProgress && (
          <Flex justify="center" align="center" style={{ marginTop: 32 }}>
            <Card style={{ width: "100%", textAlign: "center" }}>
              <Typography.Title level={4}>{renderProgress?.statusText}</Typography.Title>
              <Progress
                percent={renderProgress?.percent}
                status="active"
                strokeColor={token.colorPrimary}
              />
            </Card>
          </Flex>
        )}

      {status === KnowledgeGraphStatus.FAILED && (
        <Flex justify="center" align="center" style={{ marginTop: 32 }}>
          <Typography.Title level={4} type="danger">
            Failed to generate concept analysis. Please try again.
          </Typography.Title>
        </Flex>
      )}

      {status === KnowledgeGraphStatus.DONE && knowledgeGraph.data?.knowledgeGraph?.message && (
        <>
          <Flex justify="center" align="center" style={{ marginTop: 32 }} vertical>
            <Typography.Title level={4} style={{ marginBottom: 24 }}>
              Your knowledge graph is ready!
            </Typography.Title>
            {previewUrl && (
              <Image
                src={previewUrl}
                alt="Knowledge Graph Preview"
                style={{
                  maxWidth: "100%",
                  cursor: "pointer",
                  objectFit: "contain",
                  maxHeight: "calc(100vh - 200px)", // Adjust this value as needed
                }}
                preview={false}
                onClick={handleImageClick}
              />
            )}
            <Space style={{ marginTop: 24 }}>
              <Button
                type="primary"
                href={`${STUDY_ROUTE.getHref(computedStudyId || "")}/knowledge-graph`}
                target="_blank"
              >
                Open Knowledge Graph in New Tab
              </Button>
              <Button
                icon={<FileImageOutlined />}
                onClick={() => handleDownload(KnowledgeGraphDownloadFormats.PNG)}
                title="Download PNG"
              />
              <Button
                icon={<FilePdfOutlined />}
                onClick={() => handleDownload(KnowledgeGraphDownloadFormats.PDF)}
                title="Download PDF"
              />
            </Space>
          </Flex>
        </>
      )}
    </Spin>
  );
};
