import { Flex, Space, Typography } from "antd";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { ProjectSettings } from "store/modules/projects/types";
import { useEffect, useMemo, useState } from "react";
import { initAssistant, sendAssistantMessage } from "store/modules/research-assistant/actions";
import { ResearchPlan } from "./research-plan";
import { SelectionInput } from "./selection-input";
import { ResearchAssistantStudyType } from "store/modules/research-assistant/types";
import { ChipActions } from "./chips-actions";
import { motion } from "framer-motion";
import { RecentAudiences } from "./recent-audiences";

type InputsSelectionProps = {
  interviewMode: ProjectSettings;
  isReadOnly?: boolean;
};

const interviewModeLabelMap = {
  [ProjectSettings.DynamicInterviewMode]: "Research Goal Interview",
  [ProjectSettings.ProblemExplorationMode]: "Problem Exploration Interview",
  [ProjectSettings.CustomScriptMode]: "Custom Script Interview",
  [ProjectSettings.SolutionFeedbackMode]: "Concept Testing Interview",
  [ProjectSettings.MessageTestingMode]: "Message Testing Interview",
  [ProjectSettings.EthnographicResearchMode]: "Ethnographic Research Interview",
};

export const InputsSelection: React.FC<InputsSelectionProps> = ({ interviewMode, isReadOnly }) => {
  const dispatch = useAppDispatch();
  const researchAssistant = useAppSelector((state) => state.researchAssistant);
  const audiences = useAppSelector((state) => state.audiences);
  const problems = useAppSelector((state) => state.problems);
  const solutions = useAppSelector((state) => state.solutions);
  const researchGoal = useAppSelector((state) => state.researchGoal);
  const customScript = useAppSelector((state) => state.customScript);

  const [isReady, setIsReady] = useState(false);

  const studyType = useMemo(() => {
    switch (interviewMode) {
      case ProjectSettings.DynamicInterviewMode:
        return ResearchAssistantStudyType.DYNAMIC_INTERVIEW;
      case ProjectSettings.ProblemExplorationMode:
        return ResearchAssistantStudyType.PROBLEM_EXPLORATION;
      case ProjectSettings.CustomScriptMode:
        return ResearchAssistantStudyType.CUSTOM_SCRIPT;
      case ProjectSettings.SolutionFeedbackMode:
        return ResearchAssistantStudyType.CONCEPT_TESTING;
      case ProjectSettings.MessageTestingMode:
        return ResearchAssistantStudyType.CONCEPT_TESTING;
      case ProjectSettings.EthnographicResearchMode:
        return ResearchAssistantStudyType.DYNAMIC_INTERVIEW;
    }
  }, [interviewMode]);

  const currentQuestion = useMemo(
    () => researchAssistant.data?.question || "Who do you want to learn about?",
    [researchAssistant.data?.question]
  );

  const assistantData = useMemo(() => {
    const getComputedValue = (value: string | string[]) => {
      return !value.length ? "" : value;
    };

    switch (studyType) {
      case ResearchAssistantStudyType.DYNAMIC_INTERVIEW:
        return {
          audience: getComputedValue(researchAssistant.data?.audience || ""),
          researchGoal: researchAssistant.data?.researchGoal || "",
        };
      case ResearchAssistantStudyType.PROBLEM_EXPLORATION:
        return {
          audience: getComputedValue(researchAssistant.data?.audience || ""),
          problems: getComputedValue(researchAssistant.data?.problems || ""),
        };
      case ResearchAssistantStudyType.CUSTOM_SCRIPT:
        return {
          audience: getComputedValue(researchAssistant.data?.audience || ""),
          customScript: researchAssistant.data?.customScript || "",
        };
      case ResearchAssistantStudyType.CONCEPT_TESTING:
        return {
          audience: getComputedValue(researchAssistant.data?.audience || ""),
          problems: getComputedValue(researchAssistant.data?.problems || ""),
          solution: getComputedValue(researchAssistant.data?.solution || ""),
        };
    }
  }, [researchAssistant.data, studyType]);

  const data = useMemo(() => {
    switch (studyType) {
      case ResearchAssistantStudyType.DYNAMIC_INTERVIEW:
        return {
          audience: audiences.data.selectedAudiences,
          researchGoal: researchGoal.data,
        };
      case ResearchAssistantStudyType.PROBLEM_EXPLORATION:
        return {
          audience: audiences.data.selectedAudiences,
          problems: problems.data.selectedProblems,
        };
      case ResearchAssistantStudyType.CUSTOM_SCRIPT:
        return {
          audience: audiences.data.selectedAudiences,
          customScript: customScript.data?.customScript,
        };
      case ResearchAssistantStudyType.CONCEPT_TESTING:
        return {
          audience: audiences.data.selectedAudiences,
          problems: problems.data.selectedProblems,
          solution: solutions.data.selectedSolutions,
        };
    }
  }, [studyType, audiences, problems, solutions, researchGoal, customScript]);

  const handleSubmit = (value: string, isLiteral?: boolean) => {
    if (!researchAssistant.data?.id) return;
    dispatch(
      sendAssistantMessage({
        assistantId: researchAssistant.data?.id,
        message: value,
        study_type: studyType,
        isLiteral,
      })
    );
  };

  useEffect(() => {
    if (
      !researchAssistant.data?.id &&
      !researchAssistant.loading &&
      !researchAssistant.error &&
      !isReadOnly
    ) {
      dispatch(initAssistant());
    }
  }, [researchAssistant, dispatch, isReadOnly]);

  return (
    <div
      style={{
        height: "100vh",
        width: 978,
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      {/* Research Plan */}
      <motion.div
        style={{ paddingBlock: 40 }}
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 100, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Flex justify="end" style={{ marginBottom: 8 }}>
          {/* Interview mode */}
          <Typography.Paragraph type="secondary" style={{ marginBottom: 0 }}>
            {interviewModeLabelMap[interviewMode]}
          </Typography.Paragraph>
        </Flex>

        <ResearchPlan
          data={data}
          assistantData={assistantData}
          setIsReady={setIsReady}
          isReady={isReady}
          isReadOnly={isReadOnly}
          interviewMode={interviewMode}
        />

        {!isReady && !isReadOnly && (
          <Flex justify="end" style={{ marginTop: 16 }}>
            <ChipActions assistantData={assistantData} setIsReady={setIsReady} />
          </Flex>
        )}
      </motion.div>

      {/* Selection Input (Question + Input) */}
      {!isReady && !isReadOnly && (
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 100, y: 0 }}
          transition={{ delay: 0.5, duration: 0.5 }}
        >
          <Space direction="vertical" size="large" style={{ marginTop: 8 }}>
            <SelectionInput
              question={currentQuestion}
              onSubmit={handleSubmit}
              loading={researchAssistant.loading}
            />
          </Space>
        </motion.div>
      )}
      {researchAssistant.error && (
        <Typography.Text type="danger">
          something went wrong, please reset the assistant
        </Typography.Text>
      )}

      {/* Recent Audiences */}
      {!isReadOnly && !isReady && <RecentAudiences onClick={handleSubmit} />}
    </div>
  );
};
